// Migrated
<template lang="pug">
.row.flex-column.flex-md-row
  .col-12.col-md(
    v-for="(shoutout, idx) in shoutouts"
    :key="idx"
  )
    nuxt-link.btn.h-px-50.p-3.h5.mb-0.bg-baby-blue.hover-bg-turquoise.transition-all.text-dark.w-100.d-block.mb-3.mb-md-0(
      :to="shoutout.button_relative_url"
    ) {{ shoutout.button_text }}
</template>

<script>
import { mapState } from 'pinia'
import { rowTypes } from '@layers/web/constants/startpage.js'

export default defineNuxtComponent({
  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
    }),

    shoutouts () {
      return (this.startpage?.rows || []).filter(({ type }) => type === rowTypes.shoutoutButton).slice(0, 4)
    },
  },
})
</script>
