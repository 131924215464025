// Migrated
<template lang="pug">
.d-inline-flex.flex-column.align-items-center.text-white.charter-header-waves
  h4.d-inline.text-uppercase.font-weight-light.ls-2.mb-1(:data-i18n="title") {{ $t(title) }}
  h3.d-inline.text-uppercase.ls-3.mb-1(:data-i18n="subtitle") {{ $t(subtitle) }}
  .d-flex.align-items-center.justify-content-center.w-100.charter-header-waves-caption
    i-wave(
      width="50px"
      filled
      :font-controlled="false"
    )
    span.text-nowrap.px-2.font-weight-light.ls-2(:data-i18n="caption") {{ $t(caption) }}
    i-wave(
      width="50px"
      filled
      :font-controlled="false"
    )
</template>

<script>
export default defineNuxtComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    caption: {
      type: String,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.charter-header-waves {
  h4 {
    font-size: 1.6rem;
  }

  @media (max-width: $lg) {
    transform: scale(.9);
  }

  @media (max-width: $md) {
    transform: scale(.7);
  }

  @media (max-width: $sm) {
    transform: scale(.6);
  }
}
</style>
