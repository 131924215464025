// Migrated
<template lang="pug">
.position-relative
  .position-relative.z-0
    CharterHeaderWaves.position-absolute.right-0.top-0.z-3.mt-n2.mr-n5.mr-sm-n3.mt-sm-2.mr-md-2.m-lg-4.m-xl-5(
      title="solresorStartPage.wavesTitle"
      subtitle="solresorStartPage.wavesSubtitle"
      caption="solresorStartPage.wavesCaption"
    )

    Swiper.solresor-head-start(
      :autoplay="{ delay: 10000 }"
      loop
      constant-display-navigation
      arrows-type="special"
      pagination
      dots-align="above-bottom"
      :loading-skeleton-aspect="false"
      loading-skeleton-height="100%"
      prevent-swiper-update
      only-render-visible-slides
      arrows-size="xl"
    )
      ResponsiveImage.h-100(
        v-for="hero in startpage.heroes"
        :key="hero.id"
        :image="hero.image_cloudinary"
        :alt="hero.button_text"
        :lazy="false"
      )
        .align-items-end.px-5.px-xl-0.h-100.d-flex.mx-n3.mx-sm-0
          .container-fluid.layout-container.solresor-head-start__box.position-relative
            h1.solresor-head-start__box-title.mb-3.mb-md-4.text-break-pre-line {{ toUnixNewlines(hero.text) }}
            a.btn.btn-rounded.btn-xl.btn-orange(
              v-if="hero.button_text",
              :href="hero.button_link"
            ) {{ hero.button_text }}

  .container-fluid.layout-container.position-relative.solresor-head-start__searchbar.z-5
    .pl-3.pl-sm-5.d-flex.d-sm-block.justify-content-center.px-0(v-if="showOfficeTools")
      button#CharterSearchBarButton.btn.btn-solresor(
        type="button",
        :class="{ 'btn-solresor-active': search === 'CharterSearchBar' }",
        @click="search = 'CharterSearchBar'"
      ) {{ $t('homeSearchCharterTrips') }}
      button#RoundtripSearchBarButton.btn.btn-solresor.ml-1.ml-sm-3(
        type="button",
        :class="{ 'btn-solresor-active': search === 'PackageSearchBar' }",
        @click="search = 'PackageSearchBar'"
      ) {{ $t('homeSearchPackages') }}

    CharterSearchBar.px-0(
      v-if="showOfficeTools && search === 'CharterSearchBar'",
      :do-search="false",
      :prefill="searchPrefill",
      @search="$emit('search', [$event, 'charter'])"
    )
    PackageSearchBar.px-0(
      v-if="search === 'PackageSearchBar'",
      :do-search="false",
      :prefill="searchPackagePrefill",
      @search="$emit('search', [$event, 'packages'])"
    )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  emits: ['search'],

  data () {
    return {
      search: 'PackageSearchBar',
    }
  },

  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
      selectedAirportState: 'selectedAirport',
      selectedDestination: 'selectedDestination',
      selectedDestinationL1: 'selectedDestinationL1',
      rooms: 'rooms',
      selectedStartDate: 'selectedStartDate',
    }),

    ...mapState(useRootStore, {
      showOfficeTools: 'showOfficeTools',
    }),

    ...mapState(useCharterPackagesStore, {
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      rooms: 'rooms',
      selectedDestinations: 'selectedDestinations',
      selectedDestinationsL1: 'selectedDestinationsL1',
      selectedCategory: 'selectedCategory',
      selectedAirportPackageState: 'selectedAirport',
    }),

    searchPrefill () {
      return {
        selectedAirport: this.selectedAirportState,
        selectedDestination: this.selectedDestination,
        selectedDestinationL1: this.selectedDestinationL1,
        rooms: deepClone(this.rooms),
        selectedStartDate: deepClone(this.selectedStartDate),
      }
    },

    searchPackagePrefill () {
      return {
        selectedAirport: this.selectedAirportPackageState,
        // selectedDuration: this.selectedDuration,
        selectedCategory: this.selectedCategory,
        selectedDestinations: this.selectedDestinations,
        selectedDestinationsL1: this.selectedDestinationsL1,
        rooms: deepClone(this.rooms),
        selectedDateRangeStartDate: this.selectedDateRangeStartDate,
        selectedDateRangeEndDate: this.selectedDateRangeEndDate,
      }
    },
  },

  methods: {
    toUnixNewlines,
  },
})
</script>

<style lang="scss">
.solresor-head-start {
  height: 700px;
  min-height: 25rem;
  width: 100%;

  .swiper {
    &-pagination {
      bottom: 92px !important;
    }

    &-carousel-arrow {
      $offset: -30px;

      &-prev {
        left: $offset !important;
      }
      &-next {
        right: $offset !important;
      }
    }
  }

  .box-title {
    line-height: 80px;
  }

  @media (max-width: $lg) {
    height: 665px;
  }

  @media (max-width: ($tablet - 1px)) {
    height: 550px;
  }

  @media (max-width: $md) {
    .box-title {
      line-height: 66px;
    }
  }

  @media (max-width: $mobile) {
    height: 450px;
    min-height: 375px;

    .box-title {
      line-height: 56px;
    }
  }

  &__box {
    padding-bottom: 120px;

    @media (max-width: $lg) {
      width: auto;
    }

    @media (max-width: $sm) {
      padding-bottom: 120px;
    }

    &-title {
      font-family: $secondary-font;
      font-size: 4.7rem;
      font-weight: 900;
      line-height: 92px;
      color: $white;
      text-shadow: 2px 2px 12px rgba(0,0,0,0.3);

      @media (max-width: $lg) {
        font-size: 4rem;
        line-height: 80px;
      }

      @media (max-width: $md) {
        font-size: 3rem;
        line-height: 66px;
      }

      @media (max-width: $sm) {
        font-size: 2.15rem;
        line-height: 46px;
      }
    }
  }

  &__searchbar {
    margin-top: -70px;
  }
}
</style>
